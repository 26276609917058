
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from './Components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Sallat from './pages/sallat';
import Asnisp from './pages/Asnisp';
import Ispasn2 from './pages/ispasn2';
import Pharmacie from './pages/pharmacie';
import Sallat3 from './pages/sallat3';
import Sallat4 from './pages/sallat4';
import Sallat5 from './pages/sallat5';
import Sallat6 from './pages/sallat6';
import Sallat7 from './pages/sallat7';
import Sallat8 from './pages/sallat8';
import Sallat9 from './pages/sallat9';
import Weather from './pages/Weather.js';
import Weather2 from './pages/Weather2.js';
import Consomationapi from './pages/consomationapi';
import Consomationapiusigservices from './pages/consomotionapiuseingservices';
import Meteo from './pages/meteo';
import Hooks from './pages/hooks.js';
import Crud from './pages/crud.js';
import Addperson from './pages/addperson.js';

import Dash from './Dashbouard/dashbouard.js';

import DashLayout from './DashLayout/Layout';













function App() {
  return (
    <Router>
      <Routes>



        <Route path="/" >
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="sallat" element={<Sallat />} />
          <Route path="asn" element={<Asnisp />} />
          <Route path="asn2" element={<Ispasn2 />} />
          <Route path="pharmacie" element={<Pharmacie />} />
          <Route path="Sallat3" element={<Sallat3 />} />
          <Route path="Sallat4" element={<Sallat4 />} />
          <Route path="Sallat5" element={<Sallat5 />} />
          <Route path="Sallat6" element={<Sallat6 />} />
          <Route path="Sallat7" element={<Sallat7 />} />
          <Route path="Sallat8" element={<Sallat8 />} />
          <Route path="Sallat9" element={<Sallat9 />} />
          <Route path="Consomationapi" element={<Consomationapi />} />
          <Route path="Consomationapiusigservices" element={<Consomationapiusigservices />} />
          <Route path="Meteo" element={<Meteo />} />
          <Route path="Weather" element={<Weather />} />
          <Route path="Weather2" element={<Weather2 />} />
          <Route path="Hooks" element={<Hooks />} />
          <Route path="Crud" element={<Crud />} />
          <Route path="Addperson" element={<Addperson />} />
        </Route>
        {/* Other top-level routes */}




        {/* Dashboard Routes */}
        <Route path="/dash" element={<DashLayout />}>
          <Route index element={<Dash />} />
          <Route path="dash2" element={<Dash />} />
        </Route>



      </Routes>
    </Router>
  );
}

export default App;
